import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import {
  SOLUTIONS_ANONYMITY_ROUTE,
  SOLUTIONS_BOUNTY_ROUTE,
  SOLUTIONS_DIGITAL_AGENCIES_ROUTE,
  SOLUTIONS_ECOMMERCE_ROUTE,
  SOLUTIONS_HUNTING_ROUTE,
  SOLUTIONS_SCRAPPING_ROUTE,
  SOLUTIONS_TRAFFIC_ROUTE,
} from '../../const/routes.const';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import MainWrapper from '../../layouts';
import SummaryLayout from '../../components/SummaryLayout/SummaryLayout';
import { LanguageMap } from '../../const/language.const';
import block1RuSrc from '../../assets/images/summary/solutions/block_1_ru.png';
import block1RuSrc2x from '../../assets/images/summary/solutions/block_1_ru_2x.png';
import block1Src from '../../assets/images/summary/solutions/block_1.png';
import block1Src2x from '../../assets/images/summary/solutions/block_1_2x.png';
import block2RuSrc from '../../assets/images/summary/solutions/block_2_ru.png';
import block2RuSrc2x from '../../assets/images/summary/solutions/block_2_ru_2x.png';
import block2Src from '../../assets/images/summary/solutions/block_2.png';
import block2Src2x from '../../assets/images/summary/solutions/block_2_2x.png';
import block3Src from '../../assets/images/summary/solutions/block_3.png';
import block3Src2x from '../../assets/images/summary/solutions/block_3_2x.png';
import block4Src from '../../assets/images/summary/solutions/block_4.png';
import block4Src2x from '../../assets/images/summary/solutions/block_4_2x.png';
import block5RuSrc from '../../assets/images/summary/solutions/block_5_ru.png';
import block5RuSrc2x from '../../assets/images/summary/solutions/block_5_ru_2x.png';
import block5Src from '../../assets/images/summary/solutions/block_5.png';
import block5Src2x from '../../assets/images/summary/solutions/block_5_2x.png';
import block6Src from '../../assets/images/summary/solutions/block_6.png';
import block6Src2x from '../../assets/images/summary/solutions/block_6_2x.png';
import block7Src from '../../assets/images/summary/solutions/block_7.png';
import block7Src2x from '../../assets/images/summary/solutions/block_7_2x.png';

const SolutionsPage = (): JSX.Element => {
  const head = {
    title: <Trans>Solutions</Trans>,
    text: (
      <Trans>
        Octo Browser — технологичный, гибкий в настройках и удобный инструмент,
        позволяющий увеличивать заработок в интернет-маркетинге. Узнайте, какие
        решения мы предлагаем для развития вашего бизнеса.
      </Trans>
    ),
    light: true,
  };

  const blocks = [
    {
      title: <Trans>Affiliate Marketing</Trans>,
      text: (
        <Trans>
          Управляйте сотнями аккаунтов в любых рекламных сетях и настраивайте
          работу в команде без банов за мультиаккаунтинг.
        </Trans>
      ),
      url: SOLUTIONS_TRAFFIC_ROUTE,
      images: [
        {
          image: <ImageContainer src={block1RuSrc} srcSet={{ _2x: block1RuSrc2x }} />,
          locales: {
            include: [LanguageMap.ru.key],
          },
        },
        {
          image: <ImageContainer src={block1Src} srcSet={{ _2x: block1Src2x }} />,
          locales: {
            exclude: [LanguageMap.ru.key],
          },
        }
      ],
    },
    {
      title: <Trans>E-commerce</Trans>,
      text: (
        <Trans>
          Повышайте эффективность продаж и масштабируйте бизнес на популярных
          маркетплейсах за счет множества аккаунтов для брендов и пользователей.
        </Trans>
      ),
      url: SOLUTIONS_ECOMMERCE_ROUTE,
      images: [
        {
          image: <ImageContainer src={block2RuSrc} srcSet={{ _2x: block2RuSrc2x }} />,
          locales: {
            include: [LanguageMap.ru.key],
          },
        },
        {
          image: <ImageContainer src={block2Src} srcSet={{ _2x: block2Src2x }} />,
          locales: {
            exclude: [LanguageMap.ru.key],
          },
        }
      ],
    },
    {
      title: <Trans>Bounty & Airdrop</Trans>,
      text: (
        <Trans>
          Попробуйте Octo Browser для успешной работы на лаунчпадах с
          токенсейлами. Работайте с большим количеством KYC-аккаунтов с одного
          устройства.
        </Trans>
      ),
      url: SOLUTIONS_BOUNTY_ROUTE,
      images: [
        {
          image: <ImageContainer src={block3Src} srcSet={{ _2x: block3Src2x }} />,
        },
      ],
    },
    {
      title: <Trans>Веб-скрейпинг</Trans>,
      text: (
        <Trans>
          Используйте headless-браузер для успешного веб-скрейпинга с
          минимальным потреблением ресурсов устройства и высоким качеством
          подмен.
        </Trans>
      ),
      url: SOLUTIONS_SCRAPPING_ROUTE,
      images: [
        {
          image: <ImageContainer src={block4Src} srcSet={{ _2x: block4Src2x }} />,
        },
      ],
    },
    {
      title: <Trans>Bonus Hunting</Trans>,
      text: (
        <Trans>
          Регистрируйте любое количество профилей на букмекерских площадках и
          казино, без риска пересечения аккаунтов.
        </Trans>
      ),
      url: SOLUTIONS_HUNTING_ROUTE,
      images: [
        {
          image: <ImageContainer src={block5RuSrc} srcSet={{ _2x: block5RuSrc2x }} />,
          locales: {
            include: [LanguageMap.ru.key],
          },
        },
        {
          image: <ImageContainer src={block5Src} srcSet={{ _2x: block5Src2x }} />,
          locales: {
            exclude: [LanguageMap.ru.key],
          },
        }
      ],
    },
    {
      title: <Trans>Anonymity</Trans>,
      text: (
        <Trans>
          Создавайте виртуальные профили, маскируя данные ваших устройств.
          Сведите к минимуму обнаружение сайтами ваших реальных параметров.
        </Trans>
      ),
      url: SOLUTIONS_ANONYMITY_ROUTE,
      images: [
        {
          image: <ImageContainer src={block6Src} srcSet={{ _2x: block6Src2x }} />,
        }
      ],
    },
    {
      title: <Trans>Digital-агентства</Trans>,
      text: (
        <Trans>
          Запускайте рекламные кампании любых масштабов, без банов и пересечения
          клиентских аккаунтов. Управляйте репутацией брендов, избежав риск
          теневого бана.
        </Trans>
      ),
      url: SOLUTIONS_DIGITAL_AGENCIES_ROUTE,
      images: [
        {
          image: <ImageContainer src={block7Src} srcSet={{ _2x: block7Src2x }} />,
        }
      ],
    },
  ];
  return (
    <MainWrapper>
      <SummaryLayout head={head} blocks={blocks} />
    </MainWrapper>
  );
};

export default SolutionsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["solutionsPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
